import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import radio from "../../Assets/Projects/radio.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/editor.png";
import evklid from "../../Assets/Projects/evklid.png";
import crm from "../../Assets/Projects/crm-frontend.svg";
import lagoona from "../../Assets/Projects/lagoona.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Мои Недавние <strong className="purple">Работы </strong>
        </h1>
        <p style={{ color: "white" }}>
          Вот несколько проектов, над которыми я работал в последнее время.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {/*<Col md={4} className="project-card">*/}
          {/*  <ProjectCard*/}
          {/*    imgPath={evklid}*/}
          {/*    isBlog={false}*/}
          {/*    title="Evklid--Landing"*/}
          {/*    description="Создание макета Евклид с применением HTML и CSS, используя Flexbox для верстки. Проект адаптирован под мобильные устройства, следуя концепции Pixel Perfect, а именование классов выполнено по методологии БЭМ."*/}
          {/*    ghLink="https://github.com/RyzenDeveloper/Evklid-landing"*/}
          {/*    demoLink="https://ryzendeveloper.github.io/Evklid-landing/"*/}
          {/*  />*/}
          {/*</Col>*/}

          {/*<Col md={4} className="project-card">*/}
          {/*  <ProjectCard*/}
          {/*    imgPath={lagoona}*/}
          {/*    isBlog={false}*/}
          {/*    title="Lagoona--Landing"*/}
          {/*    description="Создание макета Lagoona с применением HTML и CSS, с соблюдением концепции Pixel Perfect и нейминга по методологии БЭМ."*/}
          {/*    ghLink="https://github.com/RyzenDeveloper/Lagoona-landing"*/}
          {/*    demoLink="https://ryzendeveloper.github.io/Lagoona-landing/"*/}
          {/*  />*/}
          {/*</Col>*/}

          {/*<Col md={4} className="project-card">*/}
          {/*  <ProjectCard*/}
          {/*    imgPath={editor}*/}
          {/*    isBlog={false}*/}
          {/*    title="3D Modeling Courses"*/}
          {/*    description="Верстка адаптивная и кросс-браузерная, с соблюдением принципов Pixel Perfect, методологии БЭМ и стандартов доступности. Использованы технологии: HTML5, CSS3, а также библиотеки и плагины, включая Bootstrap."*/}
          {/*    ghLink="https://github.com/RyzenDeveloper/3D_modeling_courses"*/}
          {/*    demoLink="https://ryzendeveloper.github.io/3D_modeling_courses/"*/}
          {/*  />*/}
          {/*</Col>*/}

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={radio}
              isBlog={false}
              title="W-wave--Landing"
              description="Создание макета Lagoona с применением HTML и CSS, с соблюдением концепции Pixel Perfect и нейминга по методологии БЭМ"
              ghLink="https://github.com/RyzenDeveloper/W-wave-radio"
              demoLink="https://ryzendeveloper.github.io/W-wave-radio/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={crm}
              isBlog={false}
              title="CRM--landing"
              description="Система управления контактными данными клиентов. Использовался JavaScript, HTML, CSS."
              ghLink="https://github.com/RyzenDeveloper/crm-frontend"
              demoLink="https://crm-pro.cpl3.dev/"
            />
          </Col>

          {/*<Col md={4} className="project-card">*/}
          {/*  <ProjectCard*/}
          {/*    imgPath={emotion}*/}
          {/*    isBlog={false}*/}
          {/*    title="Face Recognition and Emotion Detection"*/}
          {/*    description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.*/}
          {/*    Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."*/}
          {/*    // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"*/}
          {/*    demoLink="https://blogs.soumya-jit.tech/"*/}
          {/*  />*/}
          {/*</Col>*/}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
